import logo from './logo.png';
//import './App.css';
import React from "react";

import { MDBFreeBird, MDBInput, MDBCol, MDBRow, MDBCardBody, MDBCardTitle, MDBBtn, MDBContainer, MDBEdgeHeader } from
"mdbreact";
import url from  './config/Config'
//import FooterPage from './FooterPage';
//import Menu from './Menu';
//import { Link } from 'react-router-dom';
class App extends React.Component {
  constructor(props) {
    super(props);
this.state = {
usuario:'',
pass:'',
sesion:[],
log:[]
}
//if(localStorage.getItem('LoginR008')){
//window.history.back()
//}
var today = new Date(),
date = today.getFullYear();
//console.log(date);
let gestion = [
    {
      "anio": date		  
    }]
if(localStorage.getItem('GestionMinoilR008')){
    
        localStorage.removeItem('GestionMinoilR008');
        localStorage.setItem('GestionMinoilR008', JSON.stringify(gestion));
}else{
    localStorage.setItem('GestionMinoilR008', JSON.stringify(gestion));
}
}
handleChange4(e) {
  this.setState({usuario: e.target.value}); 
}

handleChange5(e) {
  this.setState({pass: e.target.value}); 
}
Ingresar=()=>{
  if(this.state.usuario!='' && this.state.pass!=''){
      
        let direccion=url.service+'login.php'+'?user='+this.state.usuario+'&contrasena='+this.state.pass;
        //console.log(direccion);
        fetch(direccion, { method: 'GET', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }}).then((response) =>
        response.json()).then((responseJson) => {
          this.setState({ sesion: Array.from(responseJson) });
          //this.setState({ log: this.state.sesion[0]});
          //console.log(this.state.sesion);
        if(this.state.sesion.length > 0){
          //console.log(this.state.sesion);
          localStorage.setItem('LoginMinoilR008', JSON.stringify(this.state.sesion));
          window.location.assign("/Menu");
            }
        else{
          alert("usuario o contraseña incorrecta");
        }
            //console.log(data2)
          });
           
      }else{
        alert("Rellene los dos campos.")
    }

    //console.log(this.state.usuario + this.state.pass)
  }
  
  
  render() {
   
  return (
    <MDBContainer className="mt-3">
        <MDBEdgeHeader color='mdb-color primary-color'></MDBEdgeHeader>
        <MDBFreeBird>
          <MDBRow>
            <MDBCol md="8" lg="7" className="mx-auto float-none white z-depth-1 py-2 px-2">
              <MDBCardBody>
                <MDBCardTitle>Sistema de Facturación Electrónica</MDBCardTitle>
                <h1 class="text-center">Iniciar Sesión</h1>
                
                <form>
                  <MDBInput label="Usuario" group type="text" icon="user" value={this.state.usuario ? this.state.usuario:''} 
                  onChange={e => this.handleChange4(e)}/>
                  <MDBInput label="Contraseña" group type="password" validate icon="lock" validate  value={this.state.pass ? this.state.pass:''}
                   onChange={e => this.handleChange5(e)} />
                  
                  <MDBBtn color={url.colorprimario} size="lg" block onClick={this.Ingresar}>INGRESAR</MDBBtn>
                  
                </form>
                
                <div className="my-2">
                  
                </div>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
        
       
			 
 					
      </MDBContainer>
     
      
  );
  }
}

export default App;
